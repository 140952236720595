<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <!-- Buequeda de afiliado -->
          <v-row>
            <!-- Numero afiliado-->
            <v-col cols="12" class="py-0" md="4">
              <v-text-field
                type="text"
                outlined
                dense
                clearable
                :disabled="isEditCoberturaEspecial"
                v-mask="'###############'"
                label="N° de afiliado"
                v-model.trim="numeroAfiliado"
                @change="buscarAfil"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                type="text"
                outlined
                clearable
                dense
                filled
                disabled
                label="Afiliado"
                :loading="loadingBtn"
                v-model.trim="afiliadoSelectedNom"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="py-0">
              <v-tooltip top max-width="20%">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">
                    {{ infoIcon }}
                  </v-icon>
                </template>
                <span v-if="!isEditCoberturaEspecial"
                  >Para buscar afiliado presione en la lupa o ingrese número de
                  afiliado</span
                >
                <span v-else>Ya hay seleccionado un afiliado</span>
              </v-tooltip>
            </v-col>
            <!-- Boton buscar por nombre o dni -->
            <v-col cols="1" class="py-0">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    absolute
                    fab
                    small
                    :disabled="isEditCoberturaEspecial"
                    color="primary"
                    @click="modalBusquedaAfiliado = true"
                  >
                    <v-icon>{{ searchIcon }}</v-icon>
                  </v-btn>
                </template>
                <span>Buscar afiliado por nombre, apellido, DNI o número</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <!-- Numero de cobertura (referencia) -->
          <v-row>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="numeroCobertura"
                label="Número de cobertura"
                dense
                outlined
                :rules="[rules.maxLength(numeroCobertura, 15)]"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <!-- Tipo de cobertura -->
          <v-row>
            <v-col cols="12" class="py-0">
              <v-autocomplete
                v-model="tipoCoberturaSelected"
                :items="tipoCobertura"
                label="Tipo de cobertura"
                return-object
                item-text="value"
                item-value="id"
                :rules="rules.required"
                @change="tipoCoberturaChange(tipoCoberturaSelected.id)"
                outlined
                clearable
                dense
              >
              </v-autocomplete>
            </v-col>
            <!-- Numero de CUD -->
            <v-col cols="12" class="py-0" v-if="showCUD">
              <v-text-field
                v-model="numeroCUD"
                label="CUD"
                dense
                outlined
                :rules="
                  showCUD
                    ? rules.required.concat(rules.maxLength(numeroCUD, 40))
                    : []
                "
              >
              </v-text-field>
            </v-col>
          </v-row>
          <!-- Medico de referencia -->
          <v-row>
            <v-col cols="12" class="py-0">
              <v-autocomplete
                ref="prestadores-autocomplete"
                v-model.trim="prestadoresSelected"
                :items="prestadores"
                :search-input.sync="prestadoresAutocompleteInput"
                item-text="value"
                item-value="id"
                @change="prestadorChange(prestadoresSelected.id)"
                :filter="customFilterPrestadores"
                outlined
                clearable
                return-object
                dense
                :rules="showPrestadores ? rules.required : []"
                autocomplete="off"
                label="Médico de referencia"
              >
                <template slot="item" slot-scope="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.value }}</v-list-item-title>
                  </v-list-item-content>
                </template>
                <template v-slot:append>
                  <v-progress-circular
                    indeterminate
                    size="28"
                    v-if="prestadoresLoading"
                    color="primary"
                  ></v-progress-circular>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <!-- Observación -->
          <v-row>
            <v-col cols="12" class="py-0">
              <v-textarea
                v-model="observacion"
                auto-grow
                :rules="[rules.maxLength(observacion, 200)]"
                dense
                outlined
                label="Observaciones"
                rows="1"
              ></v-textarea>
            </v-col>
          </v-row>
          <!-- Fecha vigencia desde -->
          <v-row>
            <v-col cols="6" class="py-0">
              <v-menu
                v-model="menuFechaDesde"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaSelectedDesde"
                    label="Vigencia desde"
                    :append-icon="calendarIcon"
                    v-bind="attrs"
                    autocomplete="not"
                    clearable
                    hint="Formato DD/MM/AAAA"
                    @blur="fechaDesde = parseDateToIso(fechaSelectedDesde)"
                    :rules="
                      rules.required.concat(
                        rules.validDate,
                        new Date(parseDateToIso(fechaSelectedDesde)) <=
                          new Date(parseDateToIso(fechaSelectedHasta)) ||
                          'Formato incorrecto'
                      )
                    "
                    outlined
                    dense
                    v-mask="'##/##/####'"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaDesde"
                  no-title
                  @change="fechaSelectedDesde = formatDate(fechaDesde)"
                  @input="menuFechaDesde = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <!-- Fecha vigencia hasta -->
            <v-col cols="6" class="py-0">
              <v-menu
                v-model="menuFechaHasta"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaSelectedHasta"
                    label="Vigencia hasta"
                    :append-icon="calendarIcon"
                    v-bind="attrs"
                    autocomplete="not"
                    clearable
                    hint="Formato DD/MM/AAAA"
                    @blur="fechaHasta = parseDateToIso(fechaSelectedHasta)"
                    :rules="
                      rules.required.concat(
                        rules.validDate,
                        new Date(parseDateToIso(fechaSelectedDesde)) <=
                          new Date(parseDateToIso(fechaSelectedHasta)) ||
                          'Formato incorrecto'
                      )
                    "
                    outlined
                    dense
                    v-mask="'##/##/####'"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaHasta"
                  no-title
                  @change="fechaSelectedHasta = formatDate(fechaHasta)"
                  @input="menuFechaHasta = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="py-0">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          :loading="loadingSaveBtn"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
      <v-dialog
        v-model="modalBusquedaAfiliado"
        v-if="modalBusquedaAfiliado"
        @keydown.esc="toggleModalBusquedaAfi"
        max-width="50%"
        persistent
      >
        <BusquedaAfiliado
          @toggleModalBusquedaAfi="toggleModalBusquedaAfi"
          @findAfiliado="findAfiliado"
        ></BusquedaAfiliado>
      </v-dialog>
    </v-container>
  </v-card>
</template>
<script>
import BusquedaAfiliado from "@/components/modules/afiliaciones/afiliados/BusquedaAfiliado.vue";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
import { mapActions } from "vuex";
export default {
  name: "EditCoberturasEspeciales",
  components: {
    BusquedaAfiliado,
  },
  directives: { mask },
  props: {
    coberturaEspecialObj: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      rules: rules,
      calendarIcon: enums.icons.CALENDAR,
      infoIcon: enums.icons.SNB_INFO,
      searchIcon: enums.icons.SEARCH,
      formEditTitle: enums.titles.EDIT_COBERTURA_ESPECIAL,
      newTitle: enums.titles.NUEVA_COBERTURA_ESPECIAL,
      isFormValid: false,
      afiliados: [],
      numeroAfiliado: null,
      afiliadoSelectedNom: null,
      afiliadoId: null,
      tipoCoberturaSelected: null,
      numeroCUD: "",
      numeroCobertura: "",
      observacion: "",
      medicoCabecera: null,
      fechaSelectedDesde: null,
      fechaSelectedHasta: null,
      prestadoresSelected: null,
      menuFechaDesde: false,
      menuFechaHasta: false,
      fechaDesde: null,
      fechaHasta: null,
      infoCoberturaEspecial: null,
      showCUD: false,
      showPrestadores: false,
      tipoCobertura: [],
      prestadores: [],
      prestadoresLoading: false,
      prestadoresAutocompleteInput: null,
      afiliadosLoading: false,
      banderaPrestadorselected: false,
      timerPrestadores: null,
      timerAfiliados: null,
      loadingSaveBtn: false,
      modalBusquedaAfiliado: false,
      loadingBtn: false,
      isEditCoberturaEspecial: false,
      findAfi: null,
      afilNombre: null
    };
  },
  async created() {
    if (this.coberturaEspecialObj) {
      this.setCoberturaEspecial();
      this.tipoCoberturaChange(this.coberturaEspecialObj.tipoCoberturaId);
    } else this.newCoberturaEspecial();
    const response = await this.fetchTiposCoberturas();
    this.tipoCobertura = response;
  },
  watch: {
    prestadoresAutocompleteInput(val) {
      if (val && val.length >= 3) {
        this.getPrestadoresFilter();
      }
    },
  },
  methods: {
    ...mapActions({
      saveCoberturaespecial: "afiliaciones/saveCoberturaespecial",
      getValidacionCoberturaEspecialByTipoCob:
        "afiliaciones/getValidacionCoberturaEspecialByTipoCob",
      getDatosCoberturaEspecialById:
        "afiliaciones/getDatosCoberturaEspecialById",
      getAfiliadosByNumero: "afiliaciones/getAfiliadosByNumero",
      fetchTiposCoberturas: "afiliaciones/fetchTiposCoberturas",
      fetchPrestadoresByQuery: "afiliaciones/fetchPrestadoresByQuery",
      setAlert: "user/setAlert",
    }),
    async setCoberturaEspecial() {
      this.banderaPrestadorselected = true;
      this.isEditCoberturaEspecial = true;
      const response = await this.getDatosCoberturaEspecialById(
        this.coberturaEspecialObj.cobEspId
      );
      this.infoCoberturaEspecial = response;
      this.afiliadoSelectedNom = this.infoCoberturaEspecial.benNom;
      if (this.infoCoberturaEspecial.preId != null) {
        this.prestadoresAutocompleteInput =
          this.infoCoberturaEspecial.medicoReferencia;
      } else this.banderaPrestadorselected = false;
      this.tipoCoberturaSelected = this.infoCoberturaEspecial.idTipoCob;
      (this.fechaSelectedDesde = this.infoCoberturaEspecial.vigenciaDesde),
        (this.fechaDesde = this.parseDateToIso(
          this.infoCoberturaEspecial.vigenciaDesde
        ));
      this.fechaSelectedHasta = this.infoCoberturaEspecial.vigenciaHasta;
      this.fechaHasta = this.parseDateToIso(
        this.infoCoberturaEspecial.vigenciaHasta
      );
      this.numeroCUD = this.infoCoberturaEspecial.cud;
      this.observacion = this.infoCoberturaEspecial.observaciones;
      this.numeroCobertura = this.infoCoberturaEspecial.numeroCobertura;
      this.numeroAfiliado = this.infoCoberturaEspecial.benNumero;
    },
    newCoberturaEspecial() {
      this.formEditTitle = this.newTitle;
    },
    prestadorChange(data) {
      this.prestadoresSelected = data;
    },
    async buscarAfil(numberAfi) {
      if (this.numeroAfiliado != null) {
        try {
          this.loadingBtn = true;
          this.findAfi = await this.getAfiliadosByNumero(numberAfi);
          this.afilNombre = this.findAfi.map((x) => x.value1);
          this.afiliadoId = this.findAfi.map((x) => x.id)[0];
          this.loadingBtn = false;
          this.afiliadoSelectedNom = this.afilNombre[0];
        } catch (error) {
          this.loadingBtn = false;
          this.afiliadoSelectedNom = null;
        }
      } else {
        this.afiliadoSelectedNom = null;
        this.afiliadoId = null;
      }
      if (this.afilNombre.length < 1) {
        this.setAlert({
          type: "warning",
          message: "N° de afiliado inexistente",
        });
        this.numeroAfiliado = null;
      }
    },
    async tipoCoberturaChange(data) {
      this.tipoCoberturaSelected = data;
      const response = await this.getValidacionCoberturaEspecialByTipoCob(data);
      switch (response.exigeCodigo) {
        case true:
          this.showCUD = true;
          break;
        case false:
          this.numeroCUD = "";
          this.showCUD = false;
      }
      switch (response.exigeMedico) {
        case true:
          this.showPrestadores = true;
          break;
        case false:
          this.prestadoresSelected = null;
          this.showPrestadores = false;
          break;
      }
    },
    getPrestadoresFilter() {
      if (this.timerPrestadores) {
        clearTimeout(this.timerPrestadores);
        this.timerPrestadores = null;
      }
      this.timerPrestadores = setTimeout(async () => {
        if (
          this.prestadores?.some(
            (x) => x.value == this.prestadoresAutocompleteInput
          )
        ) {
          return;
        } else if (!this.banderaPrestadorselected) {
          this.prestadoresLoading = true;
          const response = await this.fetchPrestadoresByQuery({
            input: this.prestadoresAutocompleteInput,
          });
          this.$refs["prestadores-autocomplete"].cachedItems = [];
          this.prestadores = response;
          this.prestadoresLoading = false;
        } else {
          this.prestadoresLoading = true;
          //toString: para mandar el dato al backend como string
          const response = await this.fetchPrestadoresByQuery({
            input: this.infoCoberturaEspecial.preId.toString(),
          });
          this.prestadores = response;
          this.prestadoresSelected = response[0].id;
          this.banderaPrestadorselected = false;
          this.prestadoresLoading = false;
        }
      }, 1000);
    },
    customFilterPrestadores(item, queryText, itemText) {
      return item.id || item.value.indexOf(queryText) > -1;
    },
    async saveEdit() {
      this.loadingSaveBtn = true;
      const data = {
        CobEspId: this.coberturaEspecialObj
          ? this.coberturaEspecialObj.cobEspId
          : 0,
        BenId: this.coberturaEspecialObj
          ? this.coberturaEspecialObj.benId
          : this.afiliadoId,
        IdTipoCob: this.tipoCoberturaSelected,
        PreId: this.prestadoresSelected ? this.prestadoresSelected : null,
        CobEspDesde: this.fechaDesde,
        CobEspHasta: this.fechaHasta,
        Observacion: this.observacion,
        CobEspNumero: this.numeroCobertura,
        CodigoDiscapacidad: this.numeroCUD,
      };
      try {
        const res = await this.saveCoberturaespecial(data);
        if (res.status === 200) {
          this.setAlert({ type: "success", message: "Guardado con éxito." });
          this.closeModal();
        }
      } catch (error) {
        this.loadingSaveBtn = false;
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    findAfiliado(afiliadoSelected) {
      this.afiliadoSelectedNom = afiliadoSelected.value;
      this.afiliadoId = afiliadoSelected.id;
      this.numeroAfiliado = afiliadoSelected.numero;
    },
    toggleModalBusquedaAfi() {
      this.modalBusquedaAfiliado = !this.modalBusquedaAfiliado;
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
  },
};
</script>