<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-4 pb-0"
      >
        <v-row>
          <v-col cols="12" md="10">
            <PageHeader :title="title" />
          </v-col>
          <v-col
            cols="12"
            md="2"
            align="end"
            v-if="canGoTiposCobEsp"
            class="mt-3"
          >
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="to-right"
                  v-on="on"
                  icon
                  v-bind="attrs"
                  @click="goToTiposCoberturasEspeciales()"
                  ><v-icon size="30px">{{ settingsIcon }}</v-icon>
                </v-btn>
              </template>
              <span>Acceder a tipo de coberturas especiales</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-card class="mx-auto mb-4 pt-0" max-width="1200" outlined>
          <v-container class="pt-0">
            <v-row>
              <v-col cols="10" class="py-0" align="left">
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                align="right"
                align-self="center"
                class="py-0 mt-2 mb-2"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="toggleFiltersSelected"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition mode="out-in">
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="loadCoberturasEspeciales()"
              >
                <!-- Filtros comunes -->
                <v-row>
                  <!-- Numero afiliado-->
                  <v-col cols="4" class="py-0" md="3">
                    <v-text-field
                      type="text"
                      outlined
                      dense
                      clearable
                      v-mask="'###############'"
                      label="N° de afiliado"
                      v-model.trim="numeroAfiliado"
                      @change="buscarAfil"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <!-- Titular de grupo -->
                  <v-col cols="12" md="4" class="py-0">
                    <v-text-field
                      type="text"
                      outlined
                      clearable
                      dense
                      filled
                      disabled
                      label="Afiliado"
                      :loading="loadingBtn"
                      v-model.trim="afiliadoSelectedNom"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="1" class="py-0">
                    <v-tooltip top max-width="20%">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">
                          {{ infoIcon }}
                        </v-icon>
                      </template>
                      <span>
                        Para buscar afiliado presione en la lupa o busque por N°
                        de afiliado
                      </span>
                    </v-tooltip>
                  </v-col>
                  <!-- Boton buscar por nombre o dni -->
                  <v-col cols="1" class="py-0">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-on="on"
                          v-bind="attrs"
                          absolute
                          fab
                          small
                          color="primary"
                          @click="modalBusquedaAfiliado = true"
                        >
                          <v-icon>{{ searchIcon }}</v-icon>
                        </v-btn>
                      </template>
                      <span
                        >Buscar afiliado por nombre, apellido, DNI o
                        número</span
                      >
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- Tipo cobertura -->
                  <v-col cols="4" class="py-0" md="5">
                    <v-autocomplete
                      v-model="tipoCoberturaSelected"
                      :items="tipoCobertura"
                      label="Tipo de cobertura"
                      return-object
                      item-text="value"
                      item-value="id"
                      outlined
                      clearable
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- Fecha vigencia -->
                  <v-col cols="4" sm="6" md="3" class="py-0">
                    <v-menu
                      ref="menu-fecha-vigencia"
                      v-model="menuFechaVigencia"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaVigenciaSelected"
                          label="Vigencia al"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          outlined
                          clearable
                          dense
                          hint="Formato DD/MM/AAAA"
                          v-mask="'##/##/####'"
                          @blur="
                            fechaVigencia = parseDateToIso(
                              fechaVigenciaSelected
                            )
                          "
                          v-on="on"
                          :rules="fechaVigencia != null ? rules.validDate : []"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaVigencia"
                        no-title
                        @change="
                          fechaVigenciaSelected = formatDate(fechaVigencia)
                        "
                        @input="menuFechaVigencia = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <!-- Fecha vencida vigencia desde -->
                  <v-col cols="4" sm="6" md="4" class="py-0">
                    <v-menu
                      ref="menu-fecha-vigencia"
                      v-model="menuVencidaVigenciaDesde"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaVencVigenciaDesde"
                          label="Solo vencidas con vigencia a partir de"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          outlined
                          clearable
                          dense
                          hint="Formato DD/MM/AAAA"
                          v-mask="'##/##/####'"
                          @blur="
                            fechaVencVigencia = parseDateToIso(
                              fechaVencVigenciaDesde
                            )
                          "
                          v-on="on"
                          :rules="
                            fechaVencVigencia != null ? rules.validDate : []
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaVencVigencia"
                        no-title
                        @change="
                          fechaVencVigenciaDesde = formatDate(fechaVencVigencia)
                        "
                        @input="menuVencidaVigenciaDesde = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="pr-4 pl-4" justify="end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        size="28"
                        @click="resetForm"
                      >
                        {{ clearFiltersIcon }}
                      </v-icon>
                    </template>
                    <span>Limpiar filtros</span>
                  </v-tooltip>
                  <v-btn
                    color="primary"
                    elevation="2"
                    small
                    type="submit"
                    form="filters-form"
                  >
                    Aplicar
                  </v-btn>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="itemsCoberturasEspeciales"
            class="elevation-1"
            :search="search"
            :loading="isLoading"
            item-key="cobEspId"
            show-expand
            single-expand
            :expanded.sync="expanded"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="10">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" align="end" v-if="canCreate">
                    <v-btn
                      color="primary"
                      @click="openModal()"
                      class="to-right"
                    >
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="canSee"
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="
                      verCoberturaEspecial(item.cobEspId, item.tipoCobertura)
                    "
                  >
                    {{ seeIcon }}
                  </v-icon>
                </template>
                <span>Ver cobertura</span>
              </v-tooltip>
              <v-tooltip left v-if="canEdit">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openModal(item)"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar afiliado</span>
              </v-tooltip>
              <v-tooltip left v-if="canDelete">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="deleteCobEspecial(item)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar cobertura especial</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="handleExpansion(item, isExpanded)"
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    >{{
                      isExpanded ? "mdi-account-check" : "mdi-account"
                    }}</v-icon
                  >
                </template>
                <span>Usuarios</span>
              </v-tooltip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <strong>Fecha alta:</strong>
                {{ item.fecAlta }}
                <strong style="padding-left: 80px">Usuario alta:</strong>
                {{ item.usuAlta }}
                <strong style="padding-left: 80px">Fecha modificación:</strong>
                {{ item.fecModi }}
                <strong style="padding-left: 80px"
                  >Usuario modificación:</strong
                >
                {{ item.usuModi }}
              </td>
            </template>
          </v-data-table>
        </v-card>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="6" md="7" align="right" class="py-3">
            <v-tooltip top max-width="40%">
              <template v-slot:activator="{ attrs }">
                <v-btn
                  color="primary"
                  class="fontsize"
                  v-bind="attrs"
                  outlined
                  :disabled="itemsCoberturasEspeciales.length == 0"
                  @click="exportExcelModelo"
                >
                  Exportar detalle
                </v-btn>
              </template>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-7 px-0"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalVerDetalle"
      v-model="openModalVerDetalle"
      max-width="75%"
      @keydown.esc="closeModalVerDetalle"
      persistent
    >
      <VerCoberturasEspeciales
        :cobEspId="cobEspId"
        :tipoCober="tipoCober"
        @closeModalVerDetalle="closeModalVerDetalle"
      ></VerCoberturasEspeciales
    ></v-dialog>
    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="50%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditCoberturasEspeciales
        :coberturaEspecialObj="coberturaEspecialObj"
        @closeAndReload="closeAndReload"
      ></EditCoberturasEspeciales>
    </v-dialog>
    <v-dialog
      v-model="modalBusquedaAfiliado"
      v-if="modalBusquedaAfiliado"
      @keydown.esc="toggleModalBusquedaAfi"
      max-width="50%"
      persistent
    >
      <BusquedaAfiliado
        @toggleModalBusquedaAfi="toggleModalBusquedaAfi"
        @findAfiliado="findAfiliado"
      ></BusquedaAfiliado>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'32%'"
      :isLoading="loadingDeleteBtn"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>
<script>
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import FiltersSelected from "@/components/shared/FiltersSelected";
import BusquedaAfiliado from "@/components/modules/afiliaciones/afiliados/BusquedaAfiliado.vue";
import EditCoberturasEspeciales from "@/components/modules/afiliaciones/afiliados/EditCoberturasEspeciales.vue";
import DeleteDialog from "@/components/shared/DeleteDialog";
import VerCoberturasEspeciales from "@/components/modules/afiliaciones/afiliados/VerCoberturasEspeciales.vue";
import { mask } from "vue-the-mask";
import helpersExcel from "@/utils/helpers/importExportExcel";
import Ayuda from "@/components/shared/Ayuda.vue";
export default {
  directives: { mask },
  components: {
    PageHeader,
    FiltersSelected,
    BusquedaAfiliado,
    Ayuda,
    EditCoberturasEspeciales,
    DeleteDialog,
    VerCoberturasEspeciales
  },
  name: "CoberturasEspeciales",
  data: vm => ({
    title: enums.titles.COBERTURAS_ESPECIALES,
    titleDelete: enums.titles.DELETE_COBERTURA_ESPECIAL,
    searchIcon: enums.icons.SEARCH,
    optionCode: enums.webSiteOptions.COBERTURAS_ESPECIALES,
    settingsIcon: enums.icons.SETTINGS,
    seeIcon: enums.icons.SEE,
    infoIcon: enums.icons.SNB_INFO,
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    loadingBtn: false,
    afiliadoSelected: null,
    numeroAfiliado: null,
    afiliadoSelectedNom: null,
    codigoAfiliado: null,
    modalBusquedaAfiliado: false,
    dialog: false,
    afiliados: [],
    itemsComprobantes: [],
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    calendarIcon: enums.icons.CALENDAR,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    menuFechaVigencia: null,
    fechaVigencia: null,
    fechaVigenciaSelected: null,
    menuVencidaVigenciaDesde: null,
    fechaVencVigencia: null,
    fechaVencVigenciaDesde: null,
    rules: rules,
    tipoCoberturaSelected: null,
    itemsCoberturasEspeciales: [],
    tipoCobertura: [],
    expanded: [],
    modalBusquedaAfiliado: false,
    showHelp: false,
    showIcon: true,
    tipoCober: "",
    showExpand: false,
    openModalVerDetalle: false,
    afiliadosLoading: false,
    canSee: false,
    canCreate: false,
    canDelete: false,
    canEdit: false,
    openModalEdit: false,
    showDeleteModal: false,
    headers: [
      {
        text: "N° de afiliado",
        align: "start",
        value: "benNumero",
        sortable: false
      },
      {
        text: "Apellido y nombre",
        align: "start",
        value: "nombreCompleto",
        sortable: false
      },
      {
        text: "Tipo de cobertura",
        align: "start",
        value: "tipoCobertura",
        sortable: false
      },
      {
        text: "Fecha desde",
        value: "coberturaDesde",
        sortable: false
      },
      {
        text: "Fecha hasta",
        value: "coberturaHasta",
        sortable: false
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" },
      {
        text: "",
        value: "data-table-expand",
        sortable: false,
        align: "end"
      }
    ],
    search: "",
    showFilters: true,
    isFormValid: true,
    isLoading: false,
    loadingDeleteBtn: false,
    filtersApplied: [],
    canGoTiposCobEsp: false,
    findAfi: null,
    afilNombre: null,
    idBen: null
  }),
  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode", //aparece como pagina visitada si ingreso por url
      this.optionCode
    );
    this.setPermisos();
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.setCobertura();
  },

  methods: {
    ...mapActions({
      getCoberturasEspeciales: "afiliaciones/getCoberturasEspeciales",
      fetchTiposCoberturas: "afiliaciones/fetchTiposCoberturas",
      deleteCoberturaEspecial: "afiliaciones/deleteCoberturaEspecial",
      getAfiliadosByNumero: "afiliaciones/getAfiliadosByNumero",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.VER_COBERTURAS_ESPECIALES:
            this.canSee = true;
            break;
          case enums.modules.adminSistema.permissions
            .TIPOS_COBERTURAS_ESPECIALES:
            this.canGoTiposCobEsp = true;
            break;
          case enums.modules.adminSistema.permissions.NUEVA_COBERTURA_ESPECIAL:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_COBERTURA_ESPECIAL:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions
            .ELIMINAR_COBERTURA_ESPECIAL:
            this.canDelete = true;
            break;
          default:
            break;
        }
      });
    },
    findAfiliado(afiliadoSelected) {
      this.idBen = afiliadoSelected.id;
      this.afiliadoSelectedNom = afiliadoSelected.value;
      this.numeroAfiliado = afiliadoSelected.numero;
    },
    async buscarAfil(numberAfi) {
      if (this.numeroAfiliado != null) {
        try {
          this.loadingBtn = true;
          this.findAfi = await this.getAfiliadosByNumero(numberAfi);
          this.afilNombre = this.findAfi.map(x => x.value1);
          this.idBen = this.findAfi.map(x => x.id)[0];
          this.loadingBtn = false;
          this.afiliadoSelectedNom = this.afilNombre[0];
        } catch (error) {
          this.loadingBtn = false;
          this.afiliadoSelectedNom = null;
        }
      } else {
        this.afiliadoSelectedNom = null;
        this.idBen = null;
      }
      if (this.afilNombre.length < 1) {
        this.setAlert({
          type: "warning",
          message: "N° de afiliado inexistente"
        });
        this.numeroAfiliado = null;
      }
    },
    async setCobertura() {
      const response = await this.fetchTiposCoberturas();
      this.tipoCobertura = response;
    },
    async loadCoberturasEspeciales() {
      this.itemsCoberturasEspeciales = [];
      if (
        this.numeroAfiliado == null &&
        this.tipoCoberturaSelected == null &&
        this.fechaVigenciaSelected == null &&
        this.fechaVencVigenciaDesde == null
      ) {
        this.setAlert({
          type: "warning",
          message: "Ingrese al menos un parámetro de búsqueda."
        });
        this.isLoading = false;
      } else {
        this.isLoading = true;
        this.showFilters = false;
        this.customizeFiltersApplied();
        const data = {
          benId: this.idBen,
          tipoCoberturaId:
            this.tipoCoberturaSelected != null
              ? this.tipoCoberturaSelected.id
              : null,
          vigenteAl: this.fechaVigenciaSelected,
          vencidoVigenteAl: this.fechaVencVigenciaDesde
        };
        const response = await this.getCoberturasEspeciales(data);
        this.itemsCoberturasEspeciales = response;
        this.isLoading = false;
      }
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    openModal(item) {
      this.openModalEdit = true;
      this.coberturaEspecialObj = item;
    },
    deleteCobEspecial(item) {
      this.showDeleteModal = true;
      this.itemToDelete = item.cobEspId;
    },
    async confirmDelete() {
      this.loadingDeleteBtn = true;
      try {
        const response = await this.deleteCoberturaEspecial(this.itemToDelete);
        if (response.status === 200) {
          this.showDeleteModal = false;
          this.setAlert({ type: "success", message: "Eliminado con éxito." });
          this.loadCoberturasEspeciales();
          this.loadingDeleteBtn = false;
        }
      } catch {
        this.loadingDeleteBtn = false;
        this.showDeleteModal = false;
      }
    },
    verCoberturaEspecial(cobEspId, tipoCobertura) {
      this.openModalVerDetalle = true;
      this.cobEspId = cobEspId;
      this.tipoCober = tipoCobertura;
    },
    closeModalVerDetalle() {
      this.openModalVerDetalle = false;
    },
    resetForm() {
      this.$refs["filters-form"].reset();
      this.idBen = null;
    },
    exportExcelModelo() {
      let result = [];
      this.itemsCoberturasEspeciales?.forEach(x =>
        result.push({
          ["CoberturaId"]: x.cobEspId,
          ["N° de afiliado"]: x.benNumero,
          ["Apellido"]: x.benApe,
          ["Nombre"]: x.benNom,
          ["Estado"]: x.estadoNom,
          ["Tipo de cobertura"]: x.tipoCobertura,
          ["Fecha desde"]: x.coberturaDesde,
          ["Fecha hasta"]: x.coberturaHasta,
          ["MedicoReferencia"]: x.medicoReferencia,
          ["Observaciones"]: x.observaciones
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(formato, "Coberturas especiales");
    },
    toggleModalBusquedaAfi() {
      this.modalBusquedaAfiliado = !this.modalBusquedaAfiliado;
    },
    async loadTipoCoberturas() {
      const tipoCober = await this.getCoberturasEspeciales();
      this.tipoCobertura = tipoCober;
    },
    closeModal() {
      this.dialog = false;
    },
    // metodos de filtros
    customizeFiltersApplied() {
      this.filtersApplied = [];
      if (this.numeroAfiliado) {
        this.filtersApplied.splice(0, 1, {
          key: "numeroAfiliado",
          label: "Afiliado",
          model: this.numeroAfiliado
        });
      }
      if (this.afiliadoSelectedNom) {
        this.filtersApplied.splice(1, 0, {
          key: "afiliadoSelectedNom",
          label: "Afiliado",
          model: this.afiliadoSelectedNom
        });
      }
      if (this.tipoCoberturaSelected) {
        this.filtersApplied.splice(2, 0, {
          key: "tipoCoberturaSelected",
          label: "Tipo de cobertura",
          model: this.tipoCoberturaSelected.value
        });
      }
      if (this.fechaVigenciaSelected) {
        this.filtersApplied.splice(3, 0, {
          key: "vigenteAl",
          label: "Vigencia al",
          model: this.fechaVigenciaSelected
        });
      }
      if (this.fechaVencVigenciaDesde) {
        this.filtersApplied.splice(4, 0, {
          key: "vencidoVigenteAl",
          label: "Solo vencidas con vigencia a partir de",
          model: this.fechaVencVigenciaDesde
        });
      }
    },
    toggleFiltersSelected() {
      this.customizeFiltersApplied();
      this.showFilters = !this.showFilters;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${month}/${day}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
    closeModalResultadoComprobantes() {
      this.loadCoberturasEspeciales();
      this.dialog = false;
      this.itemsComprobantes = [];
    },
    goToTiposCoberturasEspeciales() {
      this.$router.push({
        name: "TipoCoberturaEspecial"
      });
      this.$store.dispatch("user/changeAppLoading", {
        status: true,
        text: "",
        opaque: true
      });
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.loadCoberturasEspeciales();
    }
  }
};
</script>
<style scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.fontsize {
  font-size: 12px;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
.to-right {
  float: right;
}
</style>
