var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-card-title',[_c('span',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(_vm.formEditTitle))])]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"form":"form","id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.saveEdit()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('###############'),expression:"'###############'"}],attrs:{"type":"text","outlined":"","dense":"","clearable":"","disabled":_vm.isEditCoberturaEspecial,"label":"N° de afiliado","autocomplete":"off"},on:{"change":_vm.buscarAfil},model:{value:(_vm.numeroAfiliado),callback:function ($$v) {_vm.numeroAfiliado=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"numeroAfiliado"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"type":"text","outlined":"","clearable":"","dense":"","filled":"","disabled":"","label":"Afiliado","loading":_vm.loadingBtn,"autocomplete":"off"},model:{value:(_vm.afiliadoSelectedNom),callback:function ($$v) {_vm.afiliadoSelectedNom=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"afiliadoSelectedNom"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"top":"","max-width":"20%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.infoIcon)+" ")])]}}])},[(!_vm.isEditCoberturaEspecial)?_c('span',[_vm._v("Para buscar afiliado presione en la lupa o ingrese número de afiliado")]):_c('span',[_vm._v("Ya hay seleccionado un afiliado")])])],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"absolute":"","fab":"","small":"","disabled":_vm.isEditCoberturaEspecial,"color":"primary"},on:{"click":function($event){_vm.modalBusquedaAfiliado = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.searchIcon))])],1)]}}])},[_c('span',[_vm._v("Buscar afiliado por nombre, apellido, DNI o número")])])],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Número de cobertura","dense":"","outlined":"","rules":[_vm.rules.maxLength(_vm.numeroCobertura, 15)]},model:{value:(_vm.numeroCobertura),callback:function ($$v) {_vm.numeroCobertura=$$v},expression:"numeroCobertura"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.tipoCobertura,"label":"Tipo de cobertura","return-object":"","item-text":"value","item-value":"id","rules":_vm.rules.required,"outlined":"","clearable":"","dense":""},on:{"change":function($event){return _vm.tipoCoberturaChange(_vm.tipoCoberturaSelected.id)}},model:{value:(_vm.tipoCoberturaSelected),callback:function ($$v) {_vm.tipoCoberturaSelected=$$v},expression:"tipoCoberturaSelected"}})],1),(_vm.showCUD)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"CUD","dense":"","outlined":"","rules":_vm.showCUD
                  ? _vm.rules.required.concat(_vm.rules.maxLength(_vm.numeroCUD, 40))
                  : []},model:{value:(_vm.numeroCUD),callback:function ($$v) {_vm.numeroCUD=$$v},expression:"numeroCUD"}})],1):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-autocomplete',{ref:"prestadores-autocomplete",attrs:{"items":_vm.prestadores,"search-input":_vm.prestadoresAutocompleteInput,"item-text":"value","item-value":"id","filter":_vm.customFilterPrestadores,"outlined":"","clearable":"","return-object":"","dense":"","rules":_vm.showPrestadores ? _vm.rules.required : [],"autocomplete":"off","label":"Médico de referencia"},on:{"update:searchInput":function($event){_vm.prestadoresAutocompleteInput=$event},"update:search-input":function($event){_vm.prestadoresAutocompleteInput=$event},"change":function($event){return _vm.prestadorChange(_vm.prestadoresSelected.id)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                  var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.value))])],1)]}},{key:"append",fn:function(){return [(_vm.prestadoresLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"28","color":"primary"}}):_vm._e()]},proxy:true}]),model:{value:(_vm.prestadoresSelected),callback:function ($$v) {_vm.prestadoresSelected=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"prestadoresSelected"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"auto-grow":"","rules":[_vm.rules.maxLength(_vm.observacion, 200)],"dense":"","outlined":"","label":"Observaciones","rows":"1"},model:{value:(_vm.observacion),callback:function ($$v) {_vm.observacion=$$v},expression:"observacion"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Vigencia desde","append-icon":_vm.calendarIcon,"autocomplete":"not","clearable":"","hint":"Formato DD/MM/AAAA","rules":_vm.rules.required.concat(
                      _vm.rules.validDate,
                      new Date(_vm.parseDateToIso(_vm.fechaSelectedDesde)) <=
                        new Date(_vm.parseDateToIso(_vm.fechaSelectedHasta)) ||
                        'Formato incorrecto'
                    ),"outlined":"","dense":""},on:{"blur":function($event){_vm.fechaDesde = _vm.parseDateToIso(_vm.fechaSelectedDesde)}},model:{value:(_vm.fechaSelectedDesde),callback:function ($$v) {_vm.fechaSelectedDesde=$$v},expression:"fechaSelectedDesde"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFechaDesde),callback:function ($$v) {_vm.menuFechaDesde=$$v},expression:"menuFechaDesde"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.fechaSelectedDesde = _vm.formatDate(_vm.fechaDesde)},"input":function($event){_vm.menuFechaDesde = false}},model:{value:(_vm.fechaDesde),callback:function ($$v) {_vm.fechaDesde=$$v},expression:"fechaDesde"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Vigencia hasta","append-icon":_vm.calendarIcon,"autocomplete":"not","clearable":"","hint":"Formato DD/MM/AAAA","rules":_vm.rules.required.concat(
                      _vm.rules.validDate,
                      new Date(_vm.parseDateToIso(_vm.fechaSelectedDesde)) <=
                        new Date(_vm.parseDateToIso(_vm.fechaSelectedHasta)) ||
                        'Formato incorrecto'
                    ),"outlined":"","dense":""},on:{"blur":function($event){_vm.fechaHasta = _vm.parseDateToIso(_vm.fechaSelectedHasta)}},model:{value:(_vm.fechaSelectedHasta),callback:function ($$v) {_vm.fechaSelectedHasta=$$v},expression:"fechaSelectedHasta"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFechaHasta),callback:function ($$v) {_vm.menuFechaHasta=$$v},expression:"menuFechaHasta"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.fechaSelectedHasta = _vm.formatDate(_vm.fechaHasta)},"input":function($event){_vm.menuFechaHasta = false}},model:{value:(_vm.fechaHasta),callback:function ($$v) {_vm.fechaHasta=$$v},expression:"fechaHasta"}})],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"py-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.isFormValid,"loading":_vm.loadingSaveBtn,"form":"form","color":"primary"}},[_vm._v(" Guardar ")])],1),(_vm.modalBusquedaAfiliado)?_c('v-dialog',{attrs:{"max-width":"50%","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.toggleModalBusquedaAfi($event)}},model:{value:(_vm.modalBusquedaAfiliado),callback:function ($$v) {_vm.modalBusquedaAfiliado=$$v},expression:"modalBusquedaAfiliado"}},[_c('BusquedaAfiliado',{on:{"toggleModalBusquedaAfi":_vm.toggleModalBusquedaAfi,"findAfiliado":_vm.findAfiliado}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }